import type { Image } from '#payload/types'

export const useSrcset = (image: Ref<Image | null>) => {
  return computed(() => {
    const srcset: string[] = []

    if (image.value) {
      const { sizes, url, width } = image.value

      Object.values({
        ...sizes,
        ...(width && width <= 2400 && { original: { url, width } }),
      }).forEach((size) => {
        if (size.url && size.width) {
          srcset.push(`${encodeURI(size.url)} ${size.width}w`)
        }
      })
    }

    return srcset.join(', ')
  })
}
